<template>
  <div class="">
    <v-card :loading="loading" class="shadow-off" :disabled="loading" loader-height="2">
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <v-btn small color="btnColor" class="white--text mr-1" @click="addeditdataerror = {} ,addeditdialog = true,addeditdata={}" v-if="$nova.hasRight(right, 'add')">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>New</span>
          </v-btn>
          <bulkChartOfAccountUpload @reload="refreshData()" v-if="$nova.hasRight(right, 'add')"/>
          <v-btn class="shadow-off" text @click="refreshData()" small icon
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
        <!-- <v-data-table
          dense
          :headers="tableheader"
          :items="listdata"
          :items-per-page="-1"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.checklist_id`]="{ item }">
            <div class="" v-if="(item.checklist_id_detail || []).length > 0">
              <v-tooltip
                v-for="(v, k) in item.checklist_id_detail"
                :key="k"
                bottom
                content-class="tooltip-bottom"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    class="pl-2 mb-1 mr-1"
                  >
                    <v-icon>mdi-tag</v-icon>
                    <span class="pl-1">{{ v.ref }}</span>
                  </v-chip>
                </template>
                <span>{{ v.description }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="$nova.hasRight(right, 'edit')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="addEditForm(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="item.status && $nova.hasRight(right, 'enabledisable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableDisableAccess(item._id, 'disable')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="
                  !item.status && $nova.hasRight(right, 'enabledisable')
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableDisableAccess(item._id, 'enable')"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table> -->

        <DataTable
          :headers="headers"
          :items="formatedData"
          :enableslot="['action', 'tasklist','location']"
          :loading="loading"
          :bgColor="true"
        >
          <template v-slot:location="{ item }">
            <div v-if="item.location.length > 0 ">
              <v-chip small v-for="(location, k) in item.location" :key="k" label class="mt-1 ml-1"> 
            {{ location }}
            </v-chip>
            </div>
          </template>
          <template v-slot:tasklist="{ item }">
            <div class="" v-if="(item.checklist_id_detail || []).length > 0">
              <v-menu
                open-on-hover
                left
                offset-x
                v-for="(v, k) in item.checklist_id_detail"
                :key="k"
                :max-width="400"
                elevation-0
                
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    class="pl-2 mb-1 mr-1"
                  >
                    <v-icon>mdi-tag</v-icon>
                    <div class="pl-1">{{ v.ref }}</div>
                  </v-chip>
                </template>
                <v-card>
                  <v-card-text class="py-2 px-4">{{ v.description }}</v-card-text>
                </v-card>
              </v-menu>
              <!-- <v-tooltip
                v-for="(v, k) in item.checklist_id_detail"
                :key="k"
                bottom
                content-class="tooltip-bottom"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    class="pl-2 mb-1 mr-1"
                  >
                    <v-icon>mdi-tag</v-icon>
                    <span class="pl-1">{{ v.ref }}</span>
                  </v-chip>
                </template>
                <span>{{ v.description }}</span>
              </v-tooltip> -->
            </div>
          </template>
          <template v-slot:action="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="$nova.hasRight(right, 'edit')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="addEditForm(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="item.status && $nova.hasRight(right, 'enabledisable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="enableDisableAccess(item._id, 'disable')"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                   
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
              
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="!item.status && $nova.hasRight(right, 'enabledisable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="enableDisableAccess(item._id, 'enable')"
                  >
                     <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              
            </div>
          </template>
        </DataTable>
      </v-card-text>
    </v-card>

    <lb-dailogboxnew
      v-model="addeditdialog"
      :heading="addeditdata._id ? 'Update' : 'Add New'"
      width="600"
      :loading="addeditloading"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="6" class="my-0 py-1">
              <lb-string
                v-model="addeditdata.glcode"
                label="GL Code*"
                :error="addeditdataerror.glcode"
                
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-dropdown
                v-model="addeditdata.type"
                label="Type*"
                :error="addeditdataerror.type"
                :items="possibletypes"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                v-model="addeditdata.name"
                label="Name*"
                :error="addeditdataerror.name"
                
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                v-model="addeditdata.category"
                label="Category*"
                :error="addeditdataerror.category"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                v-model="addeditdata.subcategory"
                label="Sub Category*"
                :error="addeditdataerror.subcategory"
                
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                v-model="addeditdata.locationId"
                :items="locationItem"
                :label="$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'"
                :error="addeditdataerror.location"
                multiple
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="d-flex align-center mb-2 mt-2">
              <span class="mr-2 font-weight-bold">Checklist Reference:</span><v-btn class="shadow-off"   @click="openchecklistDialog(addeditdata.checklist_id || {})" small text><v-icon class="mr-1">mdi-plus</v-icon>Add Checklist</v-btn>
            </div>
             <div class="d-none">{{filteredItems}}</div>
            <v-simple-table class="FC-Table" dense v-if="(addeditdata.checklist_id_detail|| []).length > 0">
              <thead>
                <tr>
                  <th scope="Name">Ref</th>
                  <th scope="columns">Description</th>
                  <th scope="Action">Remove</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item,index in addeditdata.checklist_id_detail">
                  <tr :key="index">
                    <td>{{ item.ref }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                      <v-icon class="ma-1" color="error" @click="removechecklist(item)">mdi-delete</v-icon>
                    </td>
                  </tr>
                </template>
                
              </tbody>
            </v-simple-table>
              <!-- <lb-dropdown
                v-model="addeditdata.checklist_id"
                multiple
                label="Checklist"
                itemtext="description"
                itemvalue="_id"
                :items="checklist"
                :error="addeditdataerror.checklist_id"
              /> -->
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" @click="addEditItem()">
          <span v-if="addeditdata._id">Update</span>
          <span v-else>Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>



     <lb-dailogboxnew v-model="selectchecklistdialog" heading="Select Checklist to update" width="600" persistent>
        <template v-slot:body>
          <div>
            <div class="mb-2">
              <lb-string label="Search" v-model="searchcustom" hidedetails   />
            </div>
              <div class="d-flex pl-2" v-for="(v, k) in checklist" :key="k">
              <div  v-if="(v.ref + v.description).replaceAll(' ', '').toLowerCase().indexOf(searchcustom.toLowerCase()) > -1">
                <div class="d-flex">
                <v-checkbox class="mt-0 pt-0 mr-1" dense label="" v-model="selectchecklist" multiple :value="v._id"
                hide-details></v-checkbox>
                <span class="mr-1">{{ v.ref }}</span><span class=""><v-icon>mdi-circle-small</v-icon></span>
                <span class="mr-1">{{ v.description }}</span>
              </div>
            </div>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2 white--text" color="btnColor" @click="addSelectedchecklist()">Select</v-btn>
        </template>
      </lb-dailogboxnew>
      <lb-dailogboxnew v-model="viewchartaccdialog" heading="Manage Chart of Accounts" width="800">
      <template v-slot:body>
        <div>
           <div class="d-flex body-1 mb-2">
              <span class=""> Gl_code :</span>
              <div class="">{{ viewchartaccdata.name || "" }}</div>
          </div>
          <div class="d-flex align-center">
              <span>{{ viewchartaccdata.category }}</span>
              <span>
                <v-icon>mdi-chevron-right</v-icon>
              </span>
              <span>{{ viewchartaccdata.subcategory }}</span>
          </div>
          <div class="mb-2">
            <div class="d-flex align-center">
              <span class="mr-3 mt-2">Type:</span> <v-chip label small class="mr-2 mt-2" color="" v-if="(viewchartaccdata.type)">{{viewchartaccdata.type}}</v-chip>
            </div>
          </div>   
          <div class="">
              <v-row>
                <v-col cols="12" class="mt-0">
                      <div class="font-weight-bold px-4 py-1">
                          <div class="d-flex align-center">
                            <div class="body-2 pt-2">Task List</div>
                            <v-spacer></v-spacer>
                            <div v-if="(viewchartaccdata.checklist_id_detail || []).length > 0">
                                <span class="font-wight-bold">Total Count:</span>
                                {{viewchartaccdata.checklist_id_detail.length}}           
                            </div>
                          </div>
                      </div>
                      <v-simple-table dense class="mx-2 mb-2" v-if="(viewchartaccdata.checklist_id_detail || []).length > 0">
                          <template v-slot:default>
                            <thead>
                                <th class="text-left">Ref</th>
                                <th class="text-left">Description</th>
                            </thead>
                            <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                                <tr v-for="(v,k) in viewchartaccdata.checklist_id_detail" :key="k" class="">
                                  <td class="">{{v.ref}}</td>
                                  <td class="">{{v.description}}</td>
                                </tr>
                            </v-slide-y-transition>
                          </template>
                      </v-simple-table>
                      <div v-else class="grey--text px-4 mb-2">No Data</div>
                </v-col>
              </v-row>
          </div>
        </div>
      </template>
</lb-dailogboxnew>
  </div>
</template>

<script>
import bulkChartOfAccountUpload from "../../views/Components/BulkUploadChartOfAccount.vue";
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  name: "financialclose_master_chartofaccount",
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_chartofaccount",
      // generateddescription: "",
      tableheader: [
        { text: "GL Code", value: "glcode" },
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Category", value: "category" },
        { text: "Sub Category", value: "subcategory" },
        { text: "Tasklist", value: "checklist_id" },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],
      possibletypes: [
        "Asset",
        "Equity",
        "Liability",
        "Income",
        "Expense",
        "Others",
      ],
      addeditdata: {},
      addeditdataerror: {},
      addeditdialog: false,
      checklist: [],
      addeditloading: false,
      loading: false,
      listdata: [],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
        {
          value: "glcode",
          text: "GL Code",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "type",
          text: "Type",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "category",
          text: "Category",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "subcategory",
          text: "Sub Category",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
         {
          value: "location",
          text: this.$store.state.LocationHeader ? this.$store.state.LocationHeader  :'Location',
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "tasklist",
          text: "Task List",
          sortable: true,
          lock: true,
          visible: false,
        },

        {
          value: "created_at",
          text: "Created At",
          sortable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      formatedData: [],
      selectchecklistdialog:false,
      selectchecklist:[],
      searchcustom:'',
      checklistIds:[],
      viewchartaccdialog:false,
      viewchartaccdata: {},
      filteredItems:[],
      locationItem:[],
    };
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  components: {
    bulkChartOfAccountUpload,
    DataTable,
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      console.log(this.listdata, "this.listdata");
      this.listdata.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          glcode: item.glcode,
          name: item.name,
          type: item.type,
          category: item.category,
          subcategory: item.subcategory,
          tasklist: item.checklist_id,
          checklist_id_detail: item.checklist_id_detail,
          location:(item.location || []).map(item => item.name),
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
          __click: this.viewchartAcc,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    init() {
      this.refreshData();
    },
    refreshData() {
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.getData();
    },
    getData() {
      this.loading = true;
      this.$store.commit("setProjectName", "");
      return this.axios
        .post("/v2/financialclose/masters/chartofaccount/formData")
        .then((dt) => {
          if (dt.data.status === "success") {
            let formdt = dt.data.data[0] || {};
            console.log(formdt);
            this.checklist = formdt.checklist || [];
            return this.axios.post(
              "/v2/financialclose/masters/chartofaccount/get"
            );
          } else throw new Error("Error reading form data");
        })
        .then((dt) => {
          this.listdata = [];
          if (dt.data.status === "success") {
            this.listdata = dt.data.data;
            this.formateData();
          } else throw new Error(dt.data.message);
        })
        .then(() => {
           this.axios
              .post("/v2/financialclose/location/get")
              .then((ele) => {
                this.locationItem = ele.data.data;
              })
              .catch((err) => {
                console.log(err);
              });
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addEditForm(dt = {}) {
      this.addeditdata = { ...dt };
      this.addeditdata.checklist_id = { ...dt.tasklist };
      let filteredLocations = this.locationItem.filter(location => this.addeditdata && this.addeditdata.location.includes(location.name));
      let locationObjects = filteredLocations.map(location => (location._id));
      this.addeditdata.locationId = locationObjects;


      // this.addeditdata.locationId = dt.location;

        // if (dt.location && dt.location.length > 0 && dt.location[0]._id) {
        //   this.addeditdata.locationId  = dt.location[0]._id;

        //   } else {
        //     this.addeditdata.locationId = '';
        //     // Handle the case where dt.location is undefined, empty, or _id doesn't exist
        //   }

      this.addeditdataerror = {};
      this.addeditdialog = true;
    },
    addEditItem() {
    this.checklistIds = [];
    this.addeditdata.checklist_id_detail = this.addeditdata.checklist_id_detail || [];
    this.addeditdata.checklist_id_detail.forEach(item => {
        this.checklistIds.push(item._id);
    });
    this.addeditdata.checklist_id = this.checklistIds;
    let filteredLocations = [];
if (this.addeditdata && this.addeditdata.locationId && Array.isArray(this.locationItem)) {
    filteredLocations = this.locationItem.filter(location => {
        return this.addeditdata.locationId.includes(location && location._id);
    });
}
    // let filteredLocations = this.locationItem && this.locationItem.filter(location => this.addeditdata && this.addeditdata.locationId.includes( location && location._id));
    let locationObjects = filteredLocations.map(location => ({ name: location.name, _id: location._id }));
    this.addeditdata.location = locationObjects;
    this.addeditloading = true;
    let type = this.addeditdata._id ? "edit" : "add";
    let url = this.addeditdata._id ? `/v2/financialclose/masters/chartofaccount/edit/${this.addeditdata._id}` : "/v2/financialclose/masters/chartofaccount/add";
    let ndt = { ...this.addeditdata };
    delete ndt._id;
    this.axios
        .post(url, { data: ndt })
        .then((dt) => {
            if (dt.data.status === "success") {
                this.$store.commit("sbSuccess", type === "add" ? "Tasklist item added" : "Tasklist item updated");
                this.addeditdialog = false;
                this.refreshData();
            } else {
                if (dt.data.data.length > 0 && dt.data.data[0].index0)
                    this.addeditdataerror = dt.data.data[0].index0;
                else
                    throw new Error(dt.data.message);
            }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.addeditloading = false;
        });
    },
    enableDisableAccess(id, action) {
      this.loading = true;
      let url = "/v2/financialclose/masters/chartofaccount/disable/";
      if (action === "enable") {
        url = "/v2/financialclose/masters/chartofaccount/enable/";
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if (action === "enable") {
              this.$store.commit("sbSuccess", "Item enabled");
              // this.listdata[k].status = true;
            } else {
              this.$store.commit("sbSuccess", "Item disabled");
              // this.listdata[k].status = false;
            }
            this.refreshData();
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openchecklistDialog(ids){
      console.log(ids);
      this.selectchecklist = Object.values(ids);
      this.selectchecklistdialog = true;
      this.searchcustom = '';
    },
    addSelectedchecklist(){
      this.filteredItems = this.checklist.filter(item => this.selectchecklist && this.selectchecklist.includes(item._id));
      this.addeditdata.checklist_id_detail = this.filteredItems;
      this.selectchecklistdialog = false;
    },
    removechecklist(item){
      let indexItem = this.addeditdata.checklist_id_detail.indexOf(item);
      this.addeditdata.checklist_id_detail.splice(indexItem, 1);
    },
     viewchartAcc(item){
       this.viewchartaccdata = {...item};
      console.log(this.viewchartaccdata,"this.viewchecklistdata");
      this.viewchartaccdialog = true;
      //console.log(item);
    },
  },
  watch: {},
};
</script>
